.LoginPageMain {
    position: absolute;
    top: 40px;
    left: calc(50vw - 230px);
    border: 0.5px solid #ddd;
    background-color: white;
    width: 400px;
    min-height: 320px;
    padding: 20px 30px;
    text-align: center;
    border-radius: 3px;
    margin-bottom: 50px;

    #LoginLogo {
        width: 70px;
    }
    
    span.header {
        font: 400 24px/1 "helvetica neue", helvetica, arial, sans-serif;
        text-align: center;
        color: #3d454d;
    }

    .link {
        color: blue;
        cursor: pointer;
    }
    
    input[type=text],
    input[type=password],
    input[type=email],
    input[type=tel] {
        height: 41px;
        width: 100%;
        border: 1px solid #e0e2e3;
        padding-left: 10px;
        padding-right: 10px;
        //outline: none;
        border-radius: 3px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        width: calc(100% - 20px);
        
        &:focus {
            outline: none;
        }
    }

    #user, #email {
        border-radius: 3px 3px 0 0;                
    }
    #pass, #password {
        border-radius: 0px 0px 3px 3px;
        border-top: 0px;
    }  
    #first, #last {
        width: calc(50% - 24px);
        border-radius: 3px;
    }
    #first {
        float: left;
    }
    #last {
        float:  right;
    }
    .error {
        color: red;
    }

    
    input[type="submit"] {
        cursor: pointer;
        margin-top: 10px;
        text-align: center;
        border-color: transparent;
        background-color: #2996cc;
        color: white;
        border-radius: 3px;
        line-height: 15px;
        height: auto;
        padding: 12px 16px;
        width: 100%;
        min-width: 80px;
    }

    input:read-only { 
        background-color: #eee;
        color: #777;
    }
    
    a.strong {
        color: #2996cc;
        cursor: pointer;
        white-space: nowrap;
        font-weight: 700;
        text-decoration: none;
    }

    form {
        transition: 0.4s all ease;
    }

}
@media only screen and (max-width: 600px) {
    .LoginPageMain {
        background-color: #fff;
        border: 0;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 30px;
        width: calc(100vw - 60px);
    }
}